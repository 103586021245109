import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (!window.location.href.includes('localhost'))
            logEvent(analytics, 'page_view', { page_location: location.pathname + location.search });
    }, [location]);
};

export default usePageTracking;
