import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { database } from '../firebase';
import { child, ref, onValue, push, update, remove } from 'firebase/database';

/**
 * Firebase ref
 */
const CATEGORIES_REF = ref(database, '/categories');

const VID_CATEGORIES_REF = ref(database, '/vidcategories');

/**
 * Interfaces
 */
export interface Category {
    name: string;
    color: string;
    key?: string;
    undeletable?: boolean;
}

export interface CategoriesState {
    posts: Category[];
    videos: Category[];
}

/**
 * INITIAL STATE
 */
const initialState: CategoriesState = {
    posts: [],
    videos: []
};

/**
 * ACTION CREATOR
 */
const fetchPostsCategoriesSucceed = createAction<Category[]>('posts/FETCH_CATEGORIES_SUCCEED');

const fetchVidCategoriesSucceed = createAction<Category[]>('videos/FETCH_CATEGORIES_SUCCEED');

/**
 * THUNKS
 */
// classic thunk, cannot used createAsyncThunk() and async await here
// because onValue is listening to events
// POSTS CATEGORIES
export const getCategories = () => (dispatch: AppDispatch) => {
    onValue(CATEGORIES_REF, (snapshot) => {
        let results = [];
        if (snapshot.val()) {
            const arrayOfKeys = Object.keys(snapshot.val());

            results = arrayOfKeys.map((key) => {
                const obj = {
                    ...snapshot.val()[key],
                    key: key
                };
                return obj;
            });
        }
        dispatch(fetchPostsCategoriesSucceed(results));
    });
};

export function saveCategory(category: Category) {
    return () => push(CATEGORIES_REF, category);
}

export function updateCategory(key: string, category: Category) {
    return () => update(child(CATEGORIES_REF, key), category);
}

export function deleteCategory(key: string) {
    return () => remove(child(CATEGORIES_REF, key));
}

// VIDEOS CATEGORIES
export const getVidCategories = () => (dispatch: AppDispatch) => {
    onValue(VID_CATEGORIES_REF, (snapshot) => {
        let results = [];

        if (snapshot.val()) {
            const arrayOfKeys = Object.keys(snapshot.val());

            results = arrayOfKeys.map((key) => {
                const obj = {
                    ...snapshot.val()[key],
                    key: key
                };
                return obj;
            });
        }
        dispatch(fetchVidCategoriesSucceed(results));
    });
};

export function saveVidCategory(category: Category) {
    return () => push(VID_CATEGORIES_REF, category);
}

export function updateVidCategory(key: string, category: Category) {
    return () => update(child(VID_CATEGORIES_REF, key), category);
}

export function deleteVidCategory(key: string) {
    return () => remove(child(VID_CATEGORIES_REF, key));
}

/**
 * REDUCER
 */
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchPostsCategoriesSucceed, (state, action) => {
        state.posts = action.payload;
    });
    builder.addCase(fetchVidCategoriesSucceed, (state, action) => {
        state.videos = action.payload;
    });
});
