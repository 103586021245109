import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { auth } from '../firebase';
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    AuthError
} from 'firebase/auth';

/**
 * Interfaces
 */
export interface User {
    uid: string;
    displayName: string | null;
    email: string | null;
    photoURL: string | null;
}

export interface UserState {
    user: User | null;
}

/**
 * INITIAL STATE
 */
const initialState: UserState = {
    user: null
};

/**
 * ACTION CREATOR
 */
const fetchUserSucceed = createAction<User>('users/FETCH_USER_SUCCEED');

/**
 * THUNKS
 */
// classic thunk, cannot used createAsyncThunk() and async await here
// because onAuthStateChanged is listening to events
export const getUser = () => (dispatch: AppDispatch) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const usr: User = {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL
            };
            dispatch(fetchUserSucceed(usr));
        }
    });
};

export const login = (email: string, password: string) => () => {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
        })
        .catch((error: AuthError) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
        });
};

export const logout = () => () => {
    signOut(auth);
};

export const signUpWithEmail = (email: string, password: string) => () => {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
        })
        .catch((error: AuthError) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
        });
};

/**
 * REDUCER
 */
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchUserSucceed, (state, action) => {
        state.user = action.payload;
    });
});
