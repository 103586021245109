import { useState } from 'react';
import styles from './TopNavigation.module.css';
import { Media } from '../Media';
import { useAppSelector } from '../features/hooks';
import { Link, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import logo from '../assets/images/main_logo.png';

import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';

import MenuButton from '../components/MenuButton';

function TopNavigation() {
    const location = useLocation();

    const [isOpen, setOpen] = useState(false);

    const user = useAppSelector((state) => state.users.user);
    // const adminLoading = useSelector((state) => state.loading.admin);
    const adminSettings = useAppSelector((state) => state.admin.adminSettings);

    const isDashboard = user && location.pathname.includes('/dashboard');

    const smallNavStyle = `${styles.allDiv} ${styles.smallNavDiv}`;
    const smallButtons = `${styles.buttons} ${styles.smallButtons}`;
    const smallNavDropContent = `${styles.dropContent} ${styles.smallNavDropContent}`;

    function dashboardLink() {
        if (user) {
            return (
                <Link to="/dashboard" className={styles.link}>
                    <div className={styles.button}>Dashboard</div>
                </Link>
            );
        }
    }

    function dashboardMenuButton() {
        if (user) {
            return <MenuButton to="/dashboard" label="Dashboard" onClick={() => setOpen(false)} />;
        }
    }

    // Mobile Menu buttons
    function renderMobile() {
        return (
            <div className={smallNavStyle}>
                <div className={styles.mobileLogoDiv}>
                    <Image
                        as={Link}
                        to="/"
                        src={logo}
                        className={styles.mobileLogo}
                        onClick={() => setOpen(false)}
                    />
                </div>
                <Menu right isOpen={isOpen} onStateChange={(state) => setOpen(state.isOpen)}>
                    <MenuButton to="/" label="Accueil" onClick={() => setOpen(false)} />
                    <MenuButton to="/articles" label="Articles" onClick={() => setOpen(false)} />
                    <MenuButton to="/nous-rejoindre" label="Nous rejoindre" onClick={() => setOpen(false)} />
                    <MenuButton to="/a-propos" label="A propos" onClick={() => setOpen(false)} />
                    <MenuButton to="/histoire" label="Un peu d'histoire" onClick={() => setOpen(false)} />
                    <MenuButton to="/videos" label="Vidéos" onClick={() => setOpen(false)} />
                    <MenuButton to="/portfolio" label="Portfolio" onClick={() => setOpen(false)} />
                    <MenuButton to="/contact" label="Contact" onClick={() => setOpen(false)} />
                    {dashboardMenuButton()}
                </Menu>
            </div>
        );
    }

    function renderLogo() {
        const logoStyle = isDashboard ? styles.mobileLogo : styles.logo;
        return <Image as={Link} to="/" src={logo} className={logoStyle} />;
    }

    // Desktop buttons
    function renderButtons() {
        const buttonsStyle = isDashboard ? smallButtons : styles.buttons;
        const dropContentStyle = isDashboard ? smallNavDropContent : styles.dropContent;
        return (
            <div className={buttonsStyle}>
                <Link to="/" className={styles.link}>
                    <div className={styles.button}>Accueil</div>
                </Link>
                <Link to="/articles" className={styles.link}>
                    <div className={styles.button}>Articles</div>
                </Link>
                <div className={styles.dropdown}>
                    <div className={styles.dropButton}>Informations</div>
                    <div className={dropContentStyle}>
                        <Link to="/nous-rejoindre" className={styles.subLink}>
                            <div className={styles.subButton}>Nous rejoindre</div>
                        </Link>
                        <Link to="/a-propos" className={styles.subLink}>
                            <div className={styles.subButton}>A propos</div>
                        </Link>
                        <Link to="/histoire" className={styles.subLink}>
                            <div className={styles.subButton}>Un peu d'histoire</div>
                        </Link>
                    </div>
                </div>

                <Link to="/videos" className={styles.link}>
                    <div className={styles.button}>Vidéos</div>
                </Link>
                <Link to="/portfolio" className={styles.link}>
                    <div className={styles.button}>Portfolio</div>
                </Link>
                <Link to="/contact" className={styles.link}>
                    <div className={styles.button}>Contact</div>
                </Link>
                {dashboardLink()}
            </div>
        );
    }

    if (/*adminLoading ||*/ !adminSettings || (adminSettings.maintenance && user == null)) {
        return null;
    }
    const webNavStyle = isDashboard ? smallNavStyle : styles.allDiv;
    return (
        <div>
            <Media at="sm">{renderMobile()}</Media>

            <Media greaterThan="sm">
                <div className={webNavStyle}>
                    <Container>
                        <div className={styles.content}>
                            {renderLogo()}
                            {renderButtons()}
                        </div>
                    </Container>
                </div>
            </Media>
        </div>
    );
}

export default TopNavigation;
