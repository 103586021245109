import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';

import { database } from '../firebase';
import {
    ref,
    onValue,
    push,
    update,
    remove,
    child,
    query,
    orderByKey,
    limitToLast,
    endAt
} from 'firebase/database';

import { DateTime } from 'luxon';

/**
 * Firebase ref
 */
const VIDEOS_REF = ref(database, '/videos');

/**
 * Interfaces
 */

export interface Video {
    title: string;
    description: string;
    youtubeId: string;
    category: string;
    timestamp: number;
    key?: string;
}

export interface VideosState {
    video: Video | null;
    recents: Video[];
    videos: Video[];
    oldestKey: string;
    lastItemReached: boolean;
    loading?: boolean;
}

/**
 * INITIAL STATE
 */
const initialState: VideosState = {
    video: null,
    recents: [],
    videos: [],
    oldestKey: '',
    lastItemReached: false
};

/**
 * ACTION CREATOR
 */
const fetchVideoSucceed = createAction<Video>('videos/FETCH_VIDEO_SUCCEED');
// const clearVideoSucceed = createAction('videos/CLEAR_VIDEO_SUCCEED');

const loadingVideos = createAction<boolean>('videos/LOADING_VIDEOS');

const fetchRecentsSucceed = createAction<Video[]>('videos/FETCH_RECENTS_SUCCEED');
const fetchFirstVideosSucceed = createAction(
    'videos/FETCH_FIRST_VIDEOS_SUCCEED',
    (videos: Video[], oldestKey: string, lastItemReached: boolean) => {
        return {
            payload: {
                videos,
                oldestKey,
                lastItemReached
            }
        };
    }
);
const fetchMoreVideosSucceed = createAction(
    'videos/FETCH_MORE_VIDEOS_SUCCEED',
    (videos: Video[], oldestKey: string, lastItemReached: boolean) => {
        return {
            payload: {
                videos,
                oldestKey,
                lastItemReached
            }
        };
    }
);
const fetchVideosFailed = createAction<Error>('videos/FETCH_VIDEOS_FAILED');

const saveVideoSucceed = createAction<Video>('videos/SAVE_VIDEO_SUCCEED');
const updateVideoSucceed = createAction<Video>('videos/UPDATE_VIDEO_SUCCEED');
const deleteVideoSucceed = createAction<string>('videos/DELETE_VIDEO_SUCCEED');

/**
 * THUNKS
 */

// Check posts.slice for comments, all func works the same way

export function getVideo(key: string) {
    return (dispatch: AppDispatch) => {
        dispatch(loadingVideos(true));
        onValue(child(VIDEOS_REF, key), (snapshot) => {
            dispatch(fetchVideoSucceed(snapshot.val()));
        });
    };
}

// export function clearVideo() {
//     return (dispatch: AppDispatch) => {
//         dispatch(clearVideoSucceed(null));
//     };
// }

export function getFirstVideos() {
    return (dispatch: AppDispatch) => {
        dispatch(loadingVideos(true));
        const queryConstraints = [orderByKey(), limitToLast(6)];
        onValue(
            query(VIDEOS_REF, ...queryConstraints),
            (snapshot) => {
                const values = snapshot.val() ? snapshot.val() : {};
                const arrayOfKeys = Object.keys(values).sort().reverse();

                const results = arrayOfKeys.map((key) => {
                    const obj = {
                        ...values[key],
                        key: key
                    };
                    return obj;
                });
                const isLastItemReached = results.length < 6;
                const newOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                dispatch(fetchRecentsSucceed(results));
                dispatch(fetchFirstVideosSucceed(results, newOldestKey, isLastItemReached));
            },
            (error) => {
                dispatch(loadingVideos(false));
                dispatch(fetchVideosFailed(error));
            },
            { onlyOnce: true }
        );
    };
}

export function getMoreVideos(currentOldestKey: string) {
    return (dispatch: AppDispatch) => {
        if (typeof currentOldestKey === 'string' && currentOldestKey !== '') {
            dispatch(loadingVideos(true));
            const queryConstraints = [orderByKey(), endAt(currentOldestKey), limitToLast(7)];
            onValue(
                query(VIDEOS_REF, ...queryConstraints),
                (snapshot) => {
                    const arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);

                    const results = arrayOfKeys.map((key) => {
                        const obj = {
                            ...snapshot.val()[key],
                            key: key
                        };
                        return obj;
                    });

                    const newOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    const isLastItemReached = results.length < 6;
                    dispatch(fetchMoreVideosSucceed(results, newOldestKey, isLastItemReached));
                },
                (error) => {
                    dispatch(loadingVideos(false));
                    dispatch(fetchVideosFailed(error));
                },
                { onlyOnce: true }
            );
        }
    };
}

export function saveVideo(video: Video) {
    const newVideo = {
        ...video,
        timestamp: DateTime.now().toMillis()
    };

    return (dispatch: AppDispatch) =>
        push(VIDEOS_REF, newVideo).then((result) =>
            dispatch(
                saveVideoSucceed({
                    ...newVideo,
                    key: result.key ? result.key : 'firebase did not provide key??'
                })
            )
        );
}

export function updateVideo(key: string, video: Video) {
    return (dispatch: AppDispatch) =>
        update(child(VIDEOS_REF, key), video).then(() => dispatch(updateVideoSucceed({ ...video, key })));
}

export function deleteVideo(key: string) {
    return (dispatch: AppDispatch) =>
        remove(child(VIDEOS_REF, key)).then(() => dispatch(deleteVideoSucceed(key)));
}

/**
 * REDUCER
 */

function addVideo(array: Video[], newVideo: Video) {
    const newArray = array.slice();
    newArray.splice(0, 0, newVideo);
    return newArray;
}

function updateVideoInArray(array: Video[], newVideo: Video) {
    return array.map((video) => {
        if (video.key !== newVideo.key) {
            return video;
        }
        return newVideo;
    });
}

function removeVideo(array: Video[], key: string) {
    return array.filter((video) => video.key !== key);
}

export const reducer = createReducer(initialState, (builder) => {
    // EditVideo
    builder.addCase(fetchVideoSucceed, (state, action) => {
        state.video = action.payload;
        state.loading = false;
    });
    // Loading videos
    builder.addCase(loadingVideos, (state, action) => {
        state.loading = action.payload;
    });
    // First videos
    builder.addCase(fetchFirstVideosSucceed, (state, action) => {
        state.videos = action.payload.videos;
        state.lastItemReached = action.payload.lastItemReached;
        state.oldestKey = action.payload.oldestKey;
        state.loading = false;
    });
    // Recents videos
    builder.addCase(fetchRecentsSucceed, (state, action) => {
        state.recents = action.payload;
    });
    // More videos
    builder.addCase(fetchMoreVideosSucceed, (state, action) => {
        state.videos = state.videos.concat(action.payload.videos);
        state.lastItemReached = action.payload.lastItemReached;
        state.oldestKey = action.payload.oldestKey;
        state.loading = false;
    });
    // Save video
    builder.addCase(saveVideoSucceed, (state, action) => {
        const newVideos = addVideo(state.videos, action.payload);
        state.recents = newVideos;
        state.videos = newVideos;
    });
    // Update video
    builder.addCase(updateVideoSucceed, (state, action) => {
        const newVideos = updateVideoInArray(state.videos, action.payload);
        const newRecentsVideos = updateVideoInArray(state.recents, action.payload);
        state.recents = newRecentsVideos;
        state.videos = newVideos;
    });
    // Delete video
    builder.addCase(deleteVideoSucceed, (state, action) => {
        state.recents = removeVideo(state.recents, action.payload);
        state.videos = removeVideo(state.videos, action.payload);
    });
});
