import { configureStore, combineReducers } from '@reduxjs/toolkit';

import * as adminSettingsSlice from './adminSettings.slice';
import * as usersSlice from './users.slice';
import * as bannerSlice from './banner.slice';
import * as categoriesSlice from './categories.slice';
import * as portfolioSlice from './portfolio.slice';
import * as postsSlice from './posts.slice';
import * as videosSlice from './videos.slice';
import * as joinUsSlice from './joinus.slice';
import * as logSlice from './log.slice';

const store = configureStore({
    reducer: combineReducers({
        admin: adminSettingsSlice.reducer,
        posts: postsSlice.reducer,
        users: usersSlice.reducer,
        portfolio: portfolioSlice.reducer,
        categories: categoriesSlice.reducer,
        videos: videosSlice.reducer,
        banner: bannerSlice.reducer,
        joinus: joinUsSlice.reducer,
        log: logSlice.reducer
    }),
    devTools: process.env.NODE_ENV === 'development'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
