import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

import store from './features/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { MediaContextProvider } from './Media';

// using Joy UI and Material UI together
// https://mui.com/joy-ui/integrations/material-ui/
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID
} from '@mui/material/styles';
import { StyledEngineProvider, CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';

import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';

import TopNavigation from './containers/TopNavigation';
import LoadingComponent from './containers/LoadingComponent';

const Home = lazy(() => import('./containers/Home'));
const Login = lazy(() => import('./containers/Login'));
// const SignUp = lazy(() => import('./containers/SignUp')) ;
const Portfolio = lazy(() => import('./containers/Portfolio'));
const Articles = lazy(() => import('./containers/Articles'));
const PostDetails = lazy(() => import('./containers/PostDetails'));
const JoinUs = lazy(() => import('./containers/JoinUs'));
const About = lazy(() => import('./containers/About'));
const History = lazy(() => import('./containers/History'));
const Videos = lazy(() => import('./containers/Videos'));
const Contact = lazy(() => import('./containers/Contact'));

const Dashboard = lazy(() => import('./containers/Dashboard'));
const HomeDashboard = lazy(() => import('./containers/HomeDashboard'));

const EditBanner = lazy(() => import('./containers/EditBanner'));

const EditArticles = lazy(() => import('./containers/EditArticles'));
const EditCategory = lazy(() => import('./containers/EditCategory'));

const EditJoinUs = lazy(() => import('./containers/EditJoinUs'));

const EditVideos = lazy(() => import('./containers/EditVideos'));
const EditVidCategory = lazy(() => import('./containers/EditVidCategory'));

const AddPortfolio = lazy(() => import('./containers/AddPortfolio'));
const EditPortfolio = lazy(() => import('./containers/EditPortfolio'));

const EmptyPage = lazy(() => import('./components/EmptyPage'));

import theme from './theme';

/*
 ** Added height: auto, minHeight: 1OO%, position: absolute, width: 100%
 ** instead of only height: 100% for Safari browser
 */
const container = document.getElementById('root');
if (container === null) throw new Error('Root container missing');
const root = createRoot(container);
const materialTheme = materialExtendTheme();
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <MediaContextProvider>
                <StyledEngineProvider injectFirst>
                    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
                        <JoyCssVarsProvider disableTransitionOnChange theme={theme}>
                            <CssBaseline enableColorScheme />
                            <TopNavigation />
                            <LoadingComponent>
                                <Suspense
                                    fallback={
                                        <Loader active inline="centered" style={{ marginTop: '100px' }} />
                                    }
                                >
                                    <Routes>
                                        <Route path="/*" element={<Home />} />
                                        {/* <Route path="/signup" element={<SignUp />} /> */}
                                        <Route path="login" element={<Login />} />

                                        <Route path="articles" element={<Articles />} />
                                        <Route path="articles/:id/:title" element={<PostDetails />} />

                                        <Route path="nous-rejoindre" element={<JoinUs />} />

                                        <Route path="a-propos" element={<About />} />

                                        <Route path="histoire" element={<History />} />

                                        <Route path="videos" element={<Videos />} />

                                        <Route path="portfolio" element={<Portfolio />} />

                                        <Route path="contact" element={<Contact />} />

                                        <Route path="dashboard" element={<Dashboard />}>
                                            <Route index element={<HomeDashboard />} />
                                            <Route path="homecontent" element={<EditBanner />} />

                                            <Route path="news/edit" element={<EditArticles />} />
                                            <Route path="news/category" element={<EditCategory />} />

                                            <Route path="joinus" element={<EditJoinUs />} />

                                            <Route path="videos/edit" element={<EditVideos />} />
                                            <Route path="videos/category" element={<EditVidCategory />} />

                                            <Route path="portfolio/add" element={<AddPortfolio />} />
                                            <Route path="portfolio/edit" element={<EditPortfolio />} />

                                            <Route path="*" element={<EmptyPage />} />
                                        </Route>

                                        <Route path="*" element={<EmptyPage />} />
                                    </Routes>
                                </Suspense>
                            </LoadingComponent>
                        </JoyCssVarsProvider>
                    </MaterialCssVarsProvider>
                </StyledEngineProvider>
            </MediaContextProvider>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);
