import { memo } from 'react';
import styles from './Maintenance.module.css';
import logo from '../assets/images/main_logo.png';

const Maintenance = (): JSX.Element => (
    <div className={styles.all}>
        <img src={logo} alt="Logo MUDO-CHUNGDOKWAN TAE KWON DO" />
        <div className={styles.title}>Maintenance en cours</div>
        <div className={styles.text}>Veuillez revenir plus tard</div>
    </div>
);

export default memo(Maintenance);
