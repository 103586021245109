import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';

import { database } from '../firebase';
import { ref, onValue, orderByKey, query, push } from 'firebase/database';

import { DateTime } from 'luxon';

/**
 * Interfaces
 */
export interface Pricing {
    fee: number;
    license: number;
    subscription: number;
    timestamp?: number;
    total?: number;
}

export interface PricingsLog {
    adult: Pricing[];
    teen: Pricing[];
    kid: Pricing[];
}

export interface LogState {
    pricings: PricingsLog;
}

/**
 * INITIAL STATE
 */
const initialState: LogState = {
    pricings: { adult: [], teen: [], kid: [] }
};

/**
 * ACTION CREATOR
 */

const fetchAdultPricingLogsSucceed = createAction<Pricing[]>('log/FETCH_ADULT_PRICING_SUCCEED');
const fetchTeenPricingLogsSucceed = createAction<Pricing[]>('log/FETCH_TEEN_PRICING_SUCCEED');
const fetchKidPricingLogsSucceed = createAction<Pricing[]>('log/FETCH_KID_PRICING_SUCCEED');

const fetchPricingLogsFailed = createAction<Error>('log/FETCH_PRICING_FAILED');

/**
 * THUNKS
 */

// TODO : DRY this

/* Get Adult Pricing logs */
export const getAdultPricingLogs = () => (dispatch: AppDispatch) => {
    const queryConstraints = [orderByKey()];
    onValue(
        query(ref(database, '/log/pricing/adult'), ...queryConstraints),
        (snapshot) => {
            // return empty object if snapshot.val() is null
            const values = snapshot.val() ? snapshot.val() : {};
            // Convert object into an array and reverse the order to get the most recent logs
            // at the top of the page
            const arrayOfKeys = Object.keys(values).sort().reverse();

            // Add key into each item
            const results = arrayOfKeys.map((key) => {
                const obj = {
                    ...values[key],
                    date: DateTime.fromMillis(values[key].timestamp)
                        .setLocale('fr-FR')
                        .toFormat('dd LLL yyyy HH:mm:ss'),
                    id: key
                };
                return obj;
            });

            dispatch(fetchAdultPricingLogsSucceed(results));
        },
        (error) => {
            dispatch(fetchPricingLogsFailed(error));
        }
    );
};

export function logPricingAdult(pricingAdult: Pricing, newTotalAdult: number) {
    const newPricingAdult: Pricing = {
        ...pricingAdult,
        total: newTotalAdult,
        timestamp: DateTime.now().toMillis()
    };
    return () => push(ref(database, '/log/pricing/adult'), newPricingAdult);
}

/* Get Teen Pricing logs */
export const getTeenPricingLogs = () => (dispatch: AppDispatch) => {
    const queryConstraints = [orderByKey()];
    onValue(
        query(ref(database, '/log/pricing/teen'), ...queryConstraints),
        (snapshot) => {
            // return empty object if snapshot.val() is null
            const values = snapshot.val() ? snapshot.val() : {};
            // Convert object into an array and reverse the order to get the most recent logs
            // at the top of the page
            const arrayOfKeys = Object.keys(values).sort().reverse();

            // Add key into each item
            const results = arrayOfKeys.map((key) => {
                const obj = {
                    ...values[key],
                    date: DateTime.fromMillis(values[key].timestamp)
                        .setLocale('fr-FR')
                        .toFormat('dd LLL yyyy HH:mm:ss'),
                    id: key
                };
                return obj;
            });

            dispatch(fetchTeenPricingLogsSucceed(results));
        },
        (error) => {
            dispatch(fetchPricingLogsFailed(error));
        }
    );
};

export function logPricingTeen(pricingTeen: Pricing, newTotalTeen: number) {
    const newPricingTeen: Pricing = {
        ...pricingTeen,
        total: newTotalTeen,
        timestamp: DateTime.now().toMillis()
    };
    return () => push(ref(database, '/log/pricing/teen'), newPricingTeen);
}

/* Get Kid Pricing logs */
export const getKidPricingLogs = () => (dispatch: AppDispatch) => {
    const queryConstraints = [orderByKey()];
    onValue(
        query(ref(database, '/log/pricing/kid'), ...queryConstraints),
        (snapshot) => {
            // return empty object if snapshot.val() is null
            const values = snapshot.val() ? snapshot.val() : {};
            // Convert object into an array and reverse the order to get the most recent logs
            // at the top of the page
            const arrayOfKeys = Object.keys(values).sort().reverse();

            // Add key into each item
            const results = arrayOfKeys.map((key) => {
                const obj = {
                    ...values[key],
                    date: DateTime.fromMillis(values[key].timestamp)
                        .setLocale('fr-FR')
                        .toFormat('dd LLL yyyy HH:mm:ss'),
                    id: key
                };
                return obj;
            });

            dispatch(fetchKidPricingLogsSucceed(results));
        },
        (error) => {
            dispatch(fetchPricingLogsFailed(error));
        }
    );
};

export function logPricingKid(pricingKid: Pricing, newTotalKid: number) {
    const newPricingKid: Pricing = {
        ...pricingKid,
        total: newTotalKid,
        timestamp: DateTime.now().toMillis()
    };
    return () => push(ref(database, '/log/pricing/kid'), newPricingKid);
}

/**
 * REDUCER
 */
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchAdultPricingLogsSucceed, (state, action) => {
        state.pricings.adult = action.payload;
    });

    builder.addCase(fetchTeenPricingLogsSucceed, (state, action) => {
        state.pricings.teen = action.payload;
    });

    builder.addCase(fetchKidPricingLogsSucceed, (state, action) => {
        state.pricings.kid = action.payload;
    });
});
