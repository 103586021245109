import React, { FC, useEffect } from 'react';
import styles from './LoadingComponent.module.css';
import { useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { Media } from '../Media';

import ErrorPage from '../components/ErrorPage';
import Maintenance from '../components/Maintenance';
import Footer from '../components/Footer';

import { useAppDispatch, useAppSelector } from '../features/hooks';
import { getAdminSettings } from '../features/adminSettings.slice';
import { getUser } from '../features/users.slice';

import usePageTracking from '../usePageTracking';

type Props = {
    children?: React.ReactNode;
};

const LoadingComponent: FC<Props> = ({ children }): JSX.Element => {
    usePageTracking();
    const location = useLocation();

    const dispatch = useAppDispatch();

    // const adminLoading = useAppSelector((state) => state.loading.admin);
    const adminSettings = useAppSelector((state) => state.admin.adminSettings);

    // const userLoading = useAppSelector((state) => state.loading.user);

    const user = useAppSelector((state) => state.users.user);

    const isDashboard = user && location.pathname.includes('/dashboard');

    useEffect(() => {
        dispatch(getAdminSettings());
        dispatch(getUser());
    }, []);

    function renderContent() {
        if (/*!adminLoading && */ adminSettings /*&& !userLoading*/) {
            if (adminSettings.maintenance && user == null && location.pathname !== '/login') {
                return <Maintenance />;
            } else {
                const webStyle = isDashboard ? styles.contentMobile : styles.content;
                return (
                    <div>
                        <Media at="sm">
                            <div className={styles.contentMobile}>{children}</div>
                        </Media>
                        <Media greaterThan="sm">
                            <div className={webStyle}>{children}</div>
                        </Media>
                    </div>
                );
            }
        } else {
            return <ErrorPage />;
        }
    }

    return (
        <div className={styles.all}>
            <div className={styles.allContent}>
                {renderContent()}
                <Footer />
            </div>

            <CookieConsent
                debug={false}
                // enableDeclineButton
                location="bottom"
                buttonText="J'accepte"
                declineButtonText="Non merci"
                cookieName="CookieConsent"
                style={{ background: '#333333' }}
                buttonStyle={{ background: '#101579', color: '#fafafa', fontSize: '13px' }}
                declineButtonStyle={{ background: '#db2828', color: '#fafafa', fontSize: '13px' }}
            >
                En poursuivant votre navigation, vous acceptez l'utilisation de cookies permettant la mesure
                de notre audience.
            </CookieConsent>
        </div>
    );
};

export default LoadingComponent;
