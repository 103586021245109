import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';

import { database, storage } from '../firebase';
import { ref, onValue, push, update, remove, child } from 'firebase/database';
import { ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { DateTime } from 'luxon';

import { isDev, randomImg } from '../utils/devUtils';

/**
 * Firebase ref
 */
const SLIDER_REF = ref(database, '/slider');

/**
 * Interfaces
 */
export interface Photo {
    name?: string;
    url?: string;
    src?: string;
    description?: string;
    width?: number;
    height?: number;
    timestamp?: number;
    key?: string;
    size?: number;
}

export interface UploadsState {
    name: string;
    progress: number;
    preview?: string;
    isUploading: boolean;
}

export interface BannerState {
    slider: Photo[];
    homevideo: string;
    uploadsState: UploadsState[];
}

/**
 * INITIAL STATE
 */
const initialState: BannerState = {
    slider: [],
    homevideo: '',
    uploadsState: []
};

/**
 * ACTION CREATOR
 */
const fetchSliderSucceed = createAction<Photo[]>('banner/FETCH_SLIDER_SUCCEED');
const addUploadsState = createAction<UploadsState[]>('banner/ADD_UPLOADS_STATE');
const updateUploadsState = createAction<UploadsState>('banner/UPDATE_UPLOADS_STATE');

const fetchHomeVideoSucceed = createAction<string>('banner/FETCH_HOMEVIDEO_SUCCEED');

/**
 * THUNKS
 */
// SLIDER
// classic thunk, cannot used createAsyncThunk() and async await here
// because onValue is listening to events
export const getSlider = () => (dispatch: AppDispatch) => {
    onValue(SLIDER_REF, (snapshot) => {
        const photosArray: Photo[] = [];
        snapshot.forEach((childSnapshot) => {
            const photo = childSnapshot.val();
            const newPhoto = {
                ...photo,
                src: isDev() ? randomImg(photo.width, photo.height) : photo.url,
                key: childSnapshot.key
            };
            photosArray.push(newPhoto);
        });
        dispatch(fetchSliderSucceed(photosArray));
    });
};

export function selectedUploads(files: UploadsState[]) {
    return (dispatch: AppDispatch) => dispatch(addUploadsState(files));
}

export function uploadPhotoToStorage(file: File, image: HTMLImageElement) {
    const bannerStorageRef = refStorage(storage, `/slider/${file.name}`);
    const uploadTask = uploadBytesResumable(bannerStorageRef, file);

    return (dispatch: AppDispatch) =>
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                dispatch(updateUploadsState({ name: file.name, progress: progress, isUploading: true }));
                // console.log(snapshot.state);
            },
            (error) => {
                console.log('Error on upload : ', error);
            },
            () => {
                // console.log('Photo uploaded');
                // console.log(uploadTask.snapshot);
                // get image url
                getDownloadURL(bannerStorageRef).then((url) => {
                    const photoInfo = {
                        name: uploadTask.snapshot.metadata.name,
                        size: file.size,
                        width: image.width,
                        height: image.height,
                        url: url,
                        description: '',
                        timestamp: DateTime.now().toMillis()
                    };
                    // console.log('Photo info', photoInfo);
                    // add photo info to database
                    push(SLIDER_REF, photoInfo);
                    dispatch(updateUploadsState({ name: file.name, progress: 100, isUploading: false }));
                });
            }
        );
}

export function getUrl(filename: string) {
    const sliderStorageRef = refStorage(storage, `/slider/${filename}`);
    return () => getDownloadURL(sliderStorageRef);
}

// export function savePhotoInfo(photoInfo) {
//     photoInfo.timestamp = DateTime.now().toMillis();
//     return () => push(SLIDER_REF, photoInfo);
// }

export function updatePhotoInfo(key: string, photoInfo: Photo) {
    return () => update(child(SLIDER_REF, key), photoInfo);
}

export function deletePhoto(key: string, filename: string) {
    const sliderStorageRef = refStorage(storage, `/slider/${filename}`);
    deleteObject(sliderStorageRef);
    return () => remove(child(SLIDER_REF, key));
}

function updateFileInArray(files: UploadsState[], filename: string, progress: number, isUploading: boolean) {
    return files.map((file) => {
        if (file.name !== filename) {
            return file;
        }
        return {
            ...file,
            progress: progress,
            isUploading: isUploading
        };
    });
}

// VIDEO
export const getHomeVideo = () => (dispatch: AppDispatch) => {
    onValue(ref(database, '/homevideo'), (snapshot) => {
        dispatch(fetchHomeVideoSucceed(snapshot.val()));
    });
};

export const updateHomeVideo = (youtubeId: string) => () => {
    update(ref(database, `/`), { homevideo: youtubeId });
};

/**
 * REDUCER
 */
// immer let us do state.slider = action.payload
// instead of doing a shallow copy { ...state, slider: action.payload }
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchSliderSucceed, (state, action) => {
        state.slider = action.payload;
    });

    builder.addCase(addUploadsState, (state, action) => {
        state.uploadsState = action.payload;
    });

    builder.addCase(updateUploadsState, (state, action) => {
        const newUploadsState = updateFileInArray(
            state.uploadsState,
            action.payload.name,
            action.payload.progress,
            action.payload.isUploading
        );

        state.uploadsState = newUploadsState;
    });

    builder.addCase(fetchHomeVideoSucceed, (state, action) => {
        state.homevideo = action.payload;
    });
});
