import { deepmerge } from '@mui/utils';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';
// import colors from '@mui/joy/colors';
import { extendTheme as extendJoyTheme } from '@mui/joy/styles';

const muiTheme = extendMuiTheme({
    cssVarPrefix: 'joy'
});

const joyTheme = extendJoyTheme({
    // fontFamily: {
    //     body: '"Open Sans", sans serif, "Helvetica Neue", Helvetica, Arial, sans-serif'
    // },
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    solidBg: '#101579',
                    solidDisabledBg: '#7b7fcf',
                    solidHoverBg: '#2b2bda',
                    solidActiveBg: '#2b2bda'
                },
                neutral: {
                    solidColor: '#00000099',
                    solidBg: '#e0e1e2',
                    solidHoverBg: '#cacbcd',
                    solidActiveBg: '#e0e1e2'
                },
                success: {
                    solidBg: '#21ba45',
                    solidHoverBg: '#16ab39',
                    solidActiveBg: '#21ba45'
                }
            }
        },
        dark: {
            // ...same structure with different values
        }
    }
});

const theme = deepmerge(muiTheme, joyTheme);

export default theme;
