import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { database } from '../firebase';
import { ref, onValue, update } from 'firebase/database';

/**
 * Firebase ref
 */
const ADMIN_SETTINGS_REF = ref(database, '/adminsettings');

/**
 * Interfaces
 */
export interface SettingsState {
    maintenance?: boolean;
    slider?: boolean;
}

export interface AdminSettingsState {
    adminSettings: SettingsState;
}

/**
 * INITIAL STATE
 */
const initialState: AdminSettingsState = {
    adminSettings: {}
};

/**
 * ACTION CREATOR
 */
export const fetchAdminSettingsSucceed = createAction<SettingsState>('admin/FETCH_SETTINGS_SUCCEED');

/**
 * THUNKS
 */
// classic thunk, cannot used createAsyncThunk() and async await here
// because onValue is listening to events
export const getAdminSettings = () => (dispatch: AppDispatch) => {
    onValue(ADMIN_SETTINGS_REF, (snapshot) => {
        dispatch(fetchAdminSettingsSucceed(snapshot.val()));
    });
};

export const updateSettings = (value: SettingsState) => () => {
    update(ADMIN_SETTINGS_REF, value);
};

/**
 * REDUCER
 */
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchAdminSettingsSucceed, (state, action) => {
        state.adminSettings = action.payload;
    });
});
