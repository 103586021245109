import { memo } from 'react';
import styles from './Footer.module.css';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../features/hooks';

import { Adsense } from '@ctrl/react-adsense';

const Footer = () => {
    const location = useLocation();
    const currentYear = DateTime.now().toFormat('yyyy');
    const adminSettings = useAppSelector((state) => state.admin.adminSettings);

    return (
        <div hidden={location.pathname.includes('/dashboard') || !adminSettings}>
            <Adsense
                client="ca-pub-6616276293608458"
                slot="7787290130"
                style={{ display: 'block' }}
                format="display"
            />
            <div className={styles.all}>
                <p className={styles.text}>
                    © 2014-{currentYear} Mudo Chungdokwan Tae Kwon Do | Tous droits réservés
                </p>
            </div>
        </div>
    );
};

export default memo(Footer);
