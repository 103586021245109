import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { Photo, UploadsState } from './banner.slice';

import { database, storage } from '../firebase';
import { ref, onValue, push, update, remove, child } from 'firebase/database';
import { ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { DateTime } from 'luxon';

import { isDev, randomImg } from '../utils/devUtils';

/**
 * Firebase ref
 */
const PORTFOLIO_DB_REF = ref(database, '/portfolio');

/**
 * Interfaces
 */
export interface PortfolioState {
    photos: Photo[];
    uploadsState: UploadsState[];
}

/**
 * INITIAL STATE
 */
const initialState: PortfolioState = {
    photos: [],
    uploadsState: []
};

/**
 * ACTION CREATOR
 */
const fetchPortfolioSucceed = createAction<Photo[]>('portfolio/FETCH_PORTFOLIO_SUCCEED');
const addUploadsState = createAction<UploadsState[]>('portfolio/ADD_UPLOADS_STATE');
const updateUploadsState = createAction<UploadsState>('portfolio/UPDATE_UPLOADS_STATE');
// const uploadImageSucceed = createAction('portfolio/UPLOAD_IMAGE_SUCCEED');

/**
 * THUNKS
 */
// SLIDER
// classic thunk, cannot used createAsyncThunk() and async await here
// because onValue is listening to events
export const getPortfolio = () => (dispatch: AppDispatch) => {
    onValue(PORTFOLIO_DB_REF, (snapshot) => {
        const photosArray: Photo[] = [];
        snapshot.forEach((childSnapshot) => {
            const photo = childSnapshot.val();
            const newPhoto: Photo = {
                ...photo,
                src: isDev() ? randomImg(photo.width, photo.height) : photo.url,
                key: childSnapshot.key
            };
            photosArray.push(newPhoto);
        });
        dispatch(fetchPortfolioSucceed(photosArray));
    });
};

export function selectedUploads(files: UploadsState[]) {
    return (dispatch: AppDispatch) => dispatch(addUploadsState(files));
}

export function uploadPhotoToStorage(file: File, image: HTMLImageElement) {
    const portfolioStorageRef = refStorage(storage, `/portfolio/${file.name}`);
    const uploadTask = uploadBytesResumable(portfolioStorageRef, file);

    return (dispatch: AppDispatch) =>
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                dispatch(updateUploadsState({ name: file.name, progress: progress, isUploading: true }));
                // console.log(snapshot.state);
            },
            (error) => {
                console.log('Error on upload : ', error);
            },
            () => {
                // console.log('Photo uploaded');
                // console.log(uploadTask.snapshot);
                // get image url
                getDownloadURL(portfolioStorageRef).then((url) => {
                    const photoInfo = {
                        name: uploadTask.snapshot.metadata.name,
                        size: file.size,
                        width: image.width,
                        height: image.height,
                        url: url,
                        description: '',
                        timestamp: DateTime.now().toMillis()
                    };
                    // console.log('Photo info', photoInfo);
                    // add photo info to database
                    push(PORTFOLIO_DB_REF, photoInfo);
                    dispatch(updateUploadsState({ name: file.name, progress: 100, isUploading: false }));
                });
            }
        );
}

export function updatePhotoInfo(key: string, photoInfo: Photo) {
    return () => update(child(PORTFOLIO_DB_REF, key), photoInfo);
}

export function deletePhoto(key: string, filename: string) {
    const portfolioStorageRef = refStorage(storage, `/portfolio/${filename}`);
    deleteObject(portfolioStorageRef);
    return () => remove(child(PORTFOLIO_DB_REF, key));
}

function updateFileInArray(files: UploadsState[], filename: string, progress: number, isUploading: boolean) {
    return files.map((file) => {
        if (file.name !== filename) {
            return file;
        }
        return {
            ...file,
            progress: progress,
            isUploading: isUploading
        };
    });
}

/**
 * REDUCER
 */
// immer let us do state.slider = action.payload
// instead of doing a shallow copy { ...state, slider: action.payload }
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchPortfolioSucceed, (state, action) => {
        state.photos = action.payload;
    });

    builder.addCase(addUploadsState, (state, action) => {
        state.uploadsState = action.payload;
    });

    builder.addCase(updateUploadsState, (state, action) => {
        const newUploadsState = updateFileInArray(
            state.uploadsState,
            action.payload.name,
            action.payload.progress,
            action.payload.isUploading
        );

        state.uploadsState = newUploadsState;
    });
});
