import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { database } from '../firebase';
import {
    child,
    endAt,
    limitToLast,
    onValue,
    orderByKey,
    push,
    query,
    ref,
    remove,
    update
} from 'firebase/database';

import { DateTime } from 'luxon';

/**
 * Firebase ref
 */
const POSTS_REF = ref(database, '/posts');

/**
 * Interfaces
 */
export interface Post {
    title: string;
    body: string;
    category: string;
    timestamp: number;
    key?: string;
}

export interface PostsState {
    post: Post | null;
    recents: Post[];
    posts: Post[];
    oldestKey: string;
    lastItemReached: boolean;
    loading?: boolean;
}

/**
 * INITIAL STATE
 */
const initialState: PostsState = {
    post: null,
    recents: [],
    posts: [],
    oldestKey: '',
    lastItemReached: false
};

/**
 * ACTION CREATOR
 */
const fetchPostSucceed = createAction<Post>('posts/FETCH_POST_SUCCEED');

// const clearPostSucceed = createAction('posts/CLEAR_POST_SUCCEED');

const loadingPosts = createAction<boolean>('posts/LOADING_POSTS');

const fetchRecentsSucceed = createAction<Post[]>('posts/FETCH_RECENTS_SUCCEED');
const fetchFirstPostsSucceed = createAction(
    'posts/FETCH_FIRST_POSTS_SUCCEED',
    (posts: Post[], oldestKey: string, lastItemReached: boolean) => {
        return {
            payload: {
                posts,
                oldestKey,
                lastItemReached
            }
        };
    }
);
const fetchMorePostsSucceed = createAction(
    'posts/FETCH_MORE_POSTS_SUCCEED',
    (posts: Post[], oldestKey: string, lastItemReached: boolean) => {
        return {
            payload: {
                posts,
                oldestKey,
                lastItemReached
            }
        };
    }
);
const fetchPostsFailed = createAction<Error>('posts/FETCH_POSTS_FAILED');

const savePostSucceed = createAction<Post>('posts/SAVE_POST_SUCCEED');
const updatePostSucceed = createAction<Post>('posts/UPDATE_POST_SUCCEED');
const deletePostSucceed = createAction<string>('posts/DELETE_POST_SUCCEED');

/**
 * THUNKS
 */
// classic thunk, cannot used createAsyncThunk() and async await here
// because onValue is listening to events

// TODO?
//  get posts by category
//      orderByChild('category')
//      equalTo('-LKZE7v53VMFTecM3vs-') //category key

/* Get one post */
export const getPost = (key: string) => (dispatch: AppDispatch) => {
    dispatch(loadingPosts(true));
    onValue(child(POSTS_REF, key), (snapshot) => {
        dispatch(fetchPostSucceed(snapshot.val()));
    });
};

/* Clear post state */
// export const clearPost = () => (dispatch) => {
//     dispatch(clearPostSucceed(null));
// };

/* Get first 5 posts */
export const getFirstPosts = () => (dispatch: AppDispatch) => {
    // Start loading
    dispatch(loadingPosts(true));
    // Get 5 most recent posts
    const queryConstraints = [orderByKey(), limitToLast(5)];
    onValue(
        query(POSTS_REF, ...queryConstraints),
        (snapshot) => {
            // return empty object if snapshot.val() is null
            const values = snapshot.val() ? snapshot.val() : {};
            // Convert object into an array and reverse the order to get the most recent posts
            // at the top of the page
            const arrayOfKeys = Object.keys(values).sort().reverse();

            // Add key into each item
            const results = arrayOfKeys.map((key) => {
                const obj = {
                    ...values[key],
                    key: key
                };
                return obj;
            });

            // less than 5 items means the end is reached, else it is not sure
            const isLastItemReached = results.length < 5;

            // Update oldest key
            const newOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
            // For Home page
            dispatch(fetchRecentsSucceed(results));
            // For infinite scrolling in Articles/EditArticles
            dispatch(fetchFirstPostsSucceed(results, newOldestKey, isLastItemReached));
        },
        (error) => {
            dispatch(loadingPosts(false));
            dispatch(fetchPostsFailed(error));
        },
        { onlyOnce: true }
    );
};

/* Get 5 other posts after current oldest key */
export const getMorePosts = (currentOldestKey: string) => (dispatch: AppDispatch) => {
    // Check if the key is a string and not empty
    if (typeof currentOldestKey === 'string' && currentOldestKey !== '') {
        // Start loading
        dispatch(loadingPosts(true));
        // Get 6 items including the current key
        const queryConstraints = [orderByKey(), endAt(currentOldestKey), limitToLast(6)];
        onValue(
            query(POSTS_REF, ...queryConstraints),
            (snapshot) => {
                // snapshot.val() is never null because there is at least the item of the current key

                // Reverse the list like getFirstPosts()
                // + slice(1) remove the first item (current key)
                const arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);

                // Add key into each item
                const results = arrayOfKeys.map((key) => {
                    const obj = {
                        ...snapshot.val()[key],
                        key: key
                    };
                    return obj;
                });

                // Update oldest key
                const newOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                // less than 5 items means the end is reached, else it is not sure
                const isLastItemReached = results.length < 5;

                dispatch(fetchMorePostsSucceed(results, newOldestKey, isLastItemReached));
            },

            (error) => {
                dispatch(loadingPosts(false));
                dispatch(fetchPostsFailed(error));
            },
            { onlyOnce: true }
        );
    }
};

export function savePost(post: Post) {
    const newPost = {
        ...post,
        timestamp: DateTime.now().toMillis()
    };
    return (dispatch: AppDispatch) =>
        push(POSTS_REF, newPost).then((result) =>
            dispatch(
                savePostSucceed({
                    ...newPost,
                    key: result.key ? result.key : 'firebase did not provide key ??'
                })
            )
        );
}

export function updatePost(key: string, post: Post) {
    return (dispatch: AppDispatch) =>
        update(child(POSTS_REF, key), post).then(() => dispatch(updatePostSucceed({ ...post, key })));
}

export function deletePost(key: string) {
    return (dispatch: AppDispatch) =>
        remove(child(POSTS_REF, key)).then(() => dispatch(deletePostSucceed(key)));
}

/**
 * REDUCER
 */

function addPost(array: Post[], newPost: Post) {
    const newArray = array.slice();
    newArray.splice(0, 0, newPost);
    return newArray;
}

function updatePostInArray(array: Post[], newPost: Post) {
    return array.map((post) => {
        if (post.key !== newPost.key) {
            return post;
        }
        return newPost;
    });
}

function removePost(array: Post[], key: string) {
    return array.filter((post) => post.key !== key);
}

// const postSlice = createSlice({
//     name: 'post',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//         // Post detail
//         builder.addCase(fetchPostSucceed, (state, action) => {
//             state.post = action.payload.post;
//             state.loading = action.payload.loading;
//         });
//     }
// });

export const reducer = createReducer(initialState, (builder) => {
    // Post detail
    builder.addCase(fetchPostSucceed, (state, action) => {
        state.post = action.payload;
        state.loading = false;
    });

    // Loading posts
    builder.addCase(loadingPosts, (state, action) => {
        state.loading = action.payload;
    });

    // First posts
    builder.addCase(fetchFirstPostsSucceed, (state, action) => {
        state.posts = action.payload.posts;
        state.lastItemReached = action.payload.lastItemReached;
        state.oldestKey = action.payload.oldestKey;
        state.loading = false;
    });
    // Recents posts
    builder.addCase(fetchRecentsSucceed, (state, action) => {
        state.recents = action.payload;
    });
    // More posts
    builder.addCase(fetchMorePostsSucceed, (state, action) => {
        state.posts = state.posts.concat(action.payload.posts);
        state.lastItemReached = action.payload.lastItemReached;
        state.oldestKey = action.payload.oldestKey;
        state.loading = false;
    });
    // Save post
    builder.addCase(savePostSucceed, (state, action) => {
        const newPosts = addPost(state.posts, action.payload);
        state.recents = newPosts;
        state.posts = newPosts;
    });
    // Update post
    builder.addCase(updatePostSucceed, (state, action) => {
        const newPosts = updatePostInArray(state.posts, action.payload);
        const newRecentsPosts = updatePostInArray(state.recents, action.payload);
        state.recents = newRecentsPosts;
        state.posts = newPosts;
    });
    // Delete post
    builder.addCase(deletePostSucceed, (state, action) => {
        state.recents = removePost(state.recents, action.payload);
        state.posts = removePost(state.posts, action.payload);
    });
});
