import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { Pricing } from './log.slice';

import { database } from '../firebase';
import { ref, onValue, update } from 'firebase/database';
import { type Location } from '../containers/EditJoinUs/LocationModal/LocationModal';

/**
 * Interfaces
 */
export interface Season {
    start: string;
    end: string;
    years: string;
}

export interface Pricings {
    adult: Pricing;
    teen: Pricing;
    kid: Pricing;
}

export interface JoinUsState {
    season: Season | null;
    location1: Location | null;
    location2: Location | null;
    pricings: Pricings | null;
}

/**
 * INITIAL STATE
 */
const initialState: JoinUsState = {
    season: null,
    location1: null,
    location2: null,
    pricings: null
};

/**
 * ACTION CREATOR
 */
const fetchSeasonDatesSucceed = createAction<Season>('joinus/FETCH_SEASON_DATES_SUCCEED');
const updateSeasonSucceed = createAction<Season>('joinus/UPDATE_SEASON_DATES_SUCCEED');

const fetchLocation1Succeed = createAction<Location>('joinus/FETCH_LOCATION1_SUCCEED');
const updateLocation1Succeed = createAction<Location>('joinus/UPDATE_LOCATION1_SUCCEED');

const fetchLocation2Succeed = createAction<Location>('joinus/FETCH_LOCATION2_SUCCEED');
const updateLocation2Succeed = createAction<Location>('joinus/UPDATE_LOCATION2_SUCCEED');

const fetchPricingSucceed = createAction<Pricings>('joinus/FETCH_PRICING_SUCCEED');
const updatePricingSucceed = createAction<Pricings>('joinus/UPDATE_PRICING_SUCCEED');

/**
 * THUNKS
 */
// classic thunk, cannot used createAsyncThunk() and async await here
// because onValue is listening to events

/* Season dates */
export const getSeasonDates = () => (dispatch: AppDispatch) => {
    onValue(ref(database, '/joinus/season'), (snapshot) => {
        dispatch(fetchSeasonDatesSucceed(snapshot.val()));
    });
};

export function updateSeasonDates(season: Season) {
    return (dispatch: AppDispatch) =>
        update(ref(database, '/joinus/season'), season).then(() => dispatch(updateSeasonSucceed(season)));
}

/* Location 1 */
export const getLocation1 = () => (dispatch: AppDispatch) => {
    onValue(ref(database, '/joinus/location1'), (snapshot) => {
        dispatch(fetchLocation1Succeed(snapshot.val()));
    });
};

export function updateLocation1(location: Location) {
    return (dispatch: AppDispatch) =>
        update(ref(database, '/joinus/location1'), location).then(() =>
            dispatch(updateLocation1Succeed(location))
        );
}

/* Location 2 */
export const getLocation2 = () => (dispatch: AppDispatch) => {
    onValue(ref(database, '/joinus/location2'), (snapshot) => {
        dispatch(fetchLocation2Succeed(snapshot.val()));
    });
};

export function updateLocation2(location: Location) {
    return (dispatch: AppDispatch) =>
        update(ref(database, '/joinus/location2'), location).then(() =>
            dispatch(updateLocation2Succeed(location))
        );
}

/* Pricing */
export const getPricings = () => (dispatch: AppDispatch) => {
    onValue(ref(database, '/joinus/pricing'), (snapshot) => {
        dispatch(fetchPricingSucceed(snapshot.val()));
    });
};

export function updatePricings(pricings: Pricings) {
    return (dispatch: AppDispatch) =>
        update(ref(database, '/joinus/pricing'), pricings).then(() =>
            dispatch(updatePricingSucceed(pricings))
        );
}

/**
 * REDUCER
 */
export const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchSeasonDatesSucceed, (state, action) => {
        state.season = action.payload;
    });
    builder.addCase(updateSeasonSucceed, (state, action) => {
        state.season = action.payload;
    });

    builder.addCase(fetchLocation1Succeed, (state, action) => {
        state.location1 = action.payload;
    });
    builder.addCase(updateLocation1Succeed, (state, action) => {
        state.location1 = action.payload;
    });

    builder.addCase(fetchLocation2Succeed, (state, action) => {
        state.location2 = action.payload;
    });
    builder.addCase(updateLocation2Succeed, (state, action) => {
        state.location2 = action.payload;
    });

    builder.addCase(fetchPricingSucceed, (state, action) => {
        state.pricings = action.payload;
    });
    builder.addCase(updatePricingSucceed, (state, action) => {
        state.pricings = action.payload;
    });
});
