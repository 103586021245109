import styles from './MenuButton.module.css';

import { Link, useLocation } from 'react-router-dom';

type Props = {
    label: string;
    to: string;
    onClick: () => void;
};

const MenuButton = (props: Props) => {
    const { label, to, onClick } = props;
    const location = useLocation();
    return (
        <div className="menu-item">
            <div className={styles.row}>
                <div className={location.pathname === to ? styles.selectRect : styles.rect} />
                <Link
                    to={to}
                    className={location.pathname === to ? styles.activeButton : styles.button}
                    onClick={onClick}
                >
                    {label}
                </Link>
            </div>
        </div>
    );
};

export default MenuButton;
